import React from 'react'
import PropTypes from 'prop-types'
import styled, { injectGlobal } from 'react-emotion'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import BackgroundImage from './background-image'
import NavBar from './navbar'

import favicon1024 from '../../static/favicon-1024.png'

injectGlobal`
  * {
    box-sizing: border-box;
  }

  body {
    align-items: center;
    display: flex;
    font-family: Futura;
    justify-content: center;
    margin: 0;
    min-height: 100vh;
    padding: 0;
  }
`

// ##TODO## :: Make dynamic when links are ready
const showNavBar = false

const Content = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }

  .twitter-timeline {
    background: #292F33;
    border-radius: 8px;
    padding: 8px !important;

    @media screen and (min-width: 1200px) {
      bottom: 0;
      border-radius: 0 !important;
      height: 100% !important;
      max-width: 320px !important;
      position: fixed !important;
      right: 0;
      top: 0;
    }
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Software development, cloud dabbling and general tech nonsense.' },
            { name: 'keywords', content: 'Jay Lynch' },
          ]}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon1024}` }
          ]}
        >
          <html lang="en" />
        </Helmet>

        {showNavBar &&
          <NavBar />
        }

        <BackgroundImage />

        <Content>
          {children}
        </Content>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
