/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import styled from 'react-emotion'

import { Timeline } from 'react-twitter-widgets'

import Layout from '../components/layout'

const Emoji = styled('span')`
  display: block;
  font-size: 800%;

  /* Workaround for Android large emoji issue */
  @media screen and (max-width: 640px) {
    font-size: 44px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 1200%;
  }
`

const Title = styled('h1')`
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1.5rem 2rem;
  text-align: center;
`

const IndexPage = ({data}) => (
  <Layout>
    <Emoji role="img" aria-label="christmas tree">🎄</Emoji>
    <Title>jaylyn.ch</Title>

    <Timeline
      dataSource={{
        screenName: 'jaylynch',
        sourceType: 'profile'
      }}
      options={{
        chrome:"nofooter noscrollbar",
        height: 320,
        theme: 'dark',
        username: 'jaylynch'
      }} />
  </Layout>
)

export default IndexPage
/* eslint-enable jsx-a11y/accessible-emoji */
